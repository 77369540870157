export const levelName = 'Insel 4: Abakaxi';
export const ground = [
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,1,2,2,2,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,1,2,2,2,1,1,1,0,0,0,0,0,0,0,1,1,1,1,0],
    [0,1,1,1,1,1,1,1,1,1,0,0,0,1,1,1,1,1,1,0],
    [0,1,1,1,1,1,1,1,1,1,0,0,0,1,1,1,1,1,1,0],
    [0,0,0,0,1,1,1,1,1,1,0,0,0,1,1,1,1,1,1,0],
    [0,0,0,0,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
];
export const objects = [
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,5,4,6,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,4,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,4,0,5,4,6,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,6,6,0],
    [0,0,0,0,0,0,3,0,0,0,0,0,0,0,0,6,5,2,5,0],
    [0,6,4,0,0,0,0,0,3,0,0,0,0,0,0,0,0,0,6,0],
    [0,0,0,0,0,0,0,3,0,0,0,0,0,0,0,5,0,5,6,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
];

export const initalPushyPosition = [4,5];
