export const levelName = 'Insel 10: Hala Hula';
export const ground = [
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,1,0,0,0,0],
    [0,1,1,1,1,1,1,0,0,0,0,0,1,1,1,1,1,0,0,0],
    [0,1,4,1,1,1,1,1,1,1,0,0,1,1,1,1,1,0,0,0],
    [0,1,1,1,1,1,1,1,1,1,0,0,1,1,1,1,0,0,0,0],
    [0,1,1,5,2,2,2,2,1,1,0,0,0,0,0,0,0,0,0,0],
    [0,1,1,1,2,2,2,2,1,1,0,0,0,0,0,0,0,0,0,0],
    [0,1,1,1,1,2,2,2,1,1,0,0,0,0,0,0,0,0,0,0],
    [0,0,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
];
export const objects = [
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,4,2,5,0,0,0,0],
    [0,0,0,4,6,4,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,3,0,0,3,0,0,0,0,9,0,6,0,0,0,0],
    [0,0,0,0,0,0,0,8,0,0,0,0,0,5,0,0,0,0,0,0],
    [0,0,0,0,0,0,7,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,4,0,0,0,7,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,6,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,4,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
];

export const initalPushyPosition = [4,9];
