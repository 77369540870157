export const levelName = 'Insel 20: Tuamoti Tiki';
export const ground = [
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,1,1,1,1,1,1,1,1,1,1,1,1,0,0,0,0],
    [0,0,0,1,1,1,1,1,1,1,1,1,4,1,1,1,1,1,0,0],
    [0,0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,11,1,0],
    [0,0,1,1,1,1,1,1,10,1,1,1,1,1,1,1,1,1,1,0],
    [0,0,1,1,1,1,1,1,1,1,1,5,1,1,1,1,1,1,1,0],
    [0,0,1,1,1,1,1,1,1,1,2,2,2,2,1,1,1,1,1,0],
    [0,0,0,1,1,1,1,1,1,1,2,2,2,2,2,1,1,1,1,0],
    [0,0,0,0,1,1,1,1,1,1,1,1,2,2,2,1,1,0,0,0],
    [0,0,0,0,1,1,1,1,1,1,1,1,1,1,1,1,0,0,0,0],
    [0,0,0,0,0,0,1,1,1,1,1,1,1,1,1,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
];
export const objects = [
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,4,2,5,0,4,6,0,0,0,0,0,0,0,0,0],
    [0,0,0,4,0,0,0,5,0,0,0,0,0,0,0,4,3,0,0,0],
    [0,0,0,6,0,0,0,0,0,0,0,0,0,0,4,6,0,0,0,0],
    [0,0,4,0,0,3,0,0,0,0,0,0,0,0,4,0,0,6,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,4,0,0,5,0,4,0],
    [0,0,0,0,0,0,8,0,0,0,0,0,0,0,0,4,4,4,0,0],
    [0,0,0,14,0,0,6,4,0,4,0,0,0,9,0,4,0,0,0,0],
    [0,0,0,0,0,0,4,0,4,0,5,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,5,0,0,0,0,0,0,0,13,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
];

export const initalPushyPosition = [12,8];
