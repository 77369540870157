export const levelName = 'Insel 3: Jojo Filizia';
export const ground = [
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,1,1,1,1,1,1,1,1,1,1,1,1,0,0,0,0],
    [0,0,0,1,1,1,1,1,2,2,2,2,2,2,1,1,0,0,0,0],
    [0,1,1,1,1,1,1,2,2,2,2,2,2,2,1,1,0,0,0,0],
    [0,1,1,1,1,1,2,2,2,2,2,2,2,2,1,1,0,0,0,0],
    [0,1,1,1,1,1,2,2,2,2,2,2,2,1,1,1,0,0,0,0],
    [0,1,1,1,1,1,2,2,2,2,2,2,1,1,1,1,0,0,0,0],
    [0,1,1,1,1,1,1,1,1,1,1,1,1,1,0,0,0,0,0,0],
    [0,0,1,1,0,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
];
export const objects = [
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,4,0,0,0,3,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,4,0,0,0,0,0,0,0,0,0,2,6,0,0,0,0],
    [0,0,4,0,0,5,0,0,0,0,5,4,4,0,0,0,0,0,0,0],
    [0,5,0,5,6,0,0,0,6,5,0,0,6,0,0,0,0,0,0,0],
    [0,4,6,0,0,0,0,5,0,4,0,0,0,0,0,0,0,0,0,0],
    [0,5,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,3,0,0,0,3,0,0,0,0,0,0,0,0],
    [0,0,0,6,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
];

export const initalPushyPosition = [3,10];
