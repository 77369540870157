export const levelName = 'Insel 19: Waikiki';
export const ground = [
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,1,1,1,0,0,0,0,0,0,0],
    [0,0,0,1,1,1,1,1,1,1,1,1,1,1,0,0,0,0,0,0],
    [0,0,1,1,1,1,1,1,1,1,1,1,1,1,1,0,0,0,0,0],
    [0,0,1,1,1,1,1,0,0,1,1,1,1,1,1,1,1,0,0,0],
    [0,0,1,1,1,1,0,0,0,0,1,1,1,9,1,1,1,0,0,0],
    [0,0,1,1,1,1,1,0,0,0,0,1,1,1,1,1,1,0,0,0],
    [0,0,1,1,1,1,1,1,0,0,1,1,1,1,1,1,1,0,0,0],
    [0,0,0,1,1,1,1,1,1,1,1,1,11,1,1,1,1,0,0,0],
    [0,0,0,0,1,1,1,1,1,1,1,1,1,1,1,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,1,1,1,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
];
export const objects = [
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,12,0,0,4,0,0,0,0,4,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,6,0,4,4,0,0,0,0,0,0,0,0,0,0],
    [0,0,4,4,4,0,0,0,0,0,0,0,0,0,5,4,0,0,0,0],
    [0,0,6,2,4,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,6,0,5,0,0,0,0,0,0,0,14,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,5,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,6,4,4,0,0,0,0,0,4,6,0,0,0,0],
    [0,0,0,0,0,5,0,4,0,0,0,0,0,5,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,4,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
];

export const initalPushyPosition = [15,7];
