export const levelName = 'Insel 15: Mowikonga';
export const ground = [
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,1,0],
    [0,0,0,0,0,0,0,0,0,1,1,1,1,1,0,0,1,1,1,0],
    [0,0,0,0,0,1,1,1,1,1,1,1,1,1,0,0,1,1,1,0],
    [0,0,0,0,1,2,2,1,8,1,1,1,1,1,0,0,1,1,0,0],
    [0,1,1,1,2,2,2,1,10,9,1,1,1,0,0,0,0,0,0,0],
    [0,1,1,1,2,2,2,1,1,1,1,1,0,0,0,0,0,0,0,0],
    [0,1,1,1,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0],
    [0,1,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0],
    [0,0,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
];
export const objects = [
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,5,2,6,0],
    [0,0,0,0,0,0,0,0,0,5,0,0,0,0,0,0,6,0,5,0],
    [0,0,0,0,0,0,5,0,0,0,0,0,3,0,0,0,0,0,6,0],
    [0,0,0,0,6,0,0,0,0,0,0,3,0,0,0,0,6,5,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,13,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,12,0,0,0,4,0,0,0,6,0,0,0,0,0,0,0,0],
    [0,0,0,0,11,0,0,0,0,4,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,6,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
];

export const initalPushyPosition = [6,7];
