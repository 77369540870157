export const levelName = 'Insel 6: Palmalani';
export const ground = [
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,1,0,0],
    [0,1,1,1,1,1,1,0,0,0,0,0,0,0,0,1,1,0,0,0],
    [0,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,1,1,2,2,2,1,1,1,0,0,0,0,0,0,0,0,0,0,0],
    [0,1,1,2,2,2,2,2,1,1,0,0,0,0,0,1,1,1,1,0],
    [0,1,1,2,2,2,2,2,1,1,1,1,0,0,1,1,1,1,1,0],
    [0,1,1,2,2,2,2,2,1,1,1,1,3,0,1,1,1,1,1,0],
    [0,1,1,1,1,1,1,1,1,1,1,1,0,0,1,1,1,1,1,0],
    [0,1,1,1,1,1,1,1,1,1,1,0,0,0,0,0,1,1,1,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
];
export const objects = [
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,6,2,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,6,5,0,0,0,0,0,0,0,0,0,5,0,0,0],
    [0,0,0,0,0,0,0,5,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,3,0,0,0,6,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,6,0,0],
    [0,0,0,0,0,3,0,0,0,0,0,0,0,0,0,0,0,0,5,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,3,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,6,0,0,0,5,0,6,0,0],
    [0,0,0,0,0,0,0,0,0,0,5,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
];

export const initalPushyPosition = [7,8];
