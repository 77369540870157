export const levelName = 'Insel 21: Babiskiki';
export const ground = [
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,1,1,0,0],
    [0,0,0,0,0,0,0,1,1,1,0,0,0,0,1,1,1,1,1,0],
    [0,0,0,0,1,1,1,11,1,1,1,1,1,1,1,2,2,2,1,0],
    [0,0,0,0,1,1,1,1,1,1,0,0,0,1,1,2,2,2,1,0],
    [0,0,0,0,1,1,1,1,0,0,0,0,0,1,1,7,2,2,1,0],
    [0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,1,1,1,1,0],
    [0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,1,1,1,1,0],
    [0,0,0,0,0,1,1,1,1,1,1,1,1,0,0,0,1,1,1,0],
    [0,0,0,0,0,0,0,1,1,1,1,1,1,0,0,0,0,3,0,0],
    [0,0,0,0,0,0,0,1,1,1,1,1,1,3,3,0,3,3,0,0],
    [0,0,0,0,0,0,0,0,1,1,1,1,0,0,0,0,0,0,0,0],
];
export const objects = [
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,6,2,5,0,14,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,4,0,0,0,0,0,0,0,0,6,0,14,0,0,0],
    [0,0,0,0,0,0,4,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,4,0,4,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,4,0,4,0,0],
    [0,0,0,0,0,7,0,4,0,0,0,4,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,7,0,7,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,7,0,0,0,0,0,0,3,7,0,0],
    [0,0,0,0,0,0,0,0,0,0,4,0,0,0,0,0,0,0,0,0],
];

export const initalPushyPosition = [12,4];
