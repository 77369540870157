export const levelName = 'Insel 2: Kuka Kuki';
export const ground = [
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,1,1,1,0,0],
    [0,0,0,1,1,1,0,0,0,0,0,0,0,1,1,1,1,1,0,0],
    [0,0,1,1,1,1,1,0,0,0,0,0,0,1,1,1,1,1,0,0],
    [0,1,1,2,2,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,1,2,2,2,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0],
    [0,1,2,2,2,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0],
    [0,1,2,2,2,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0],
    [0,1,1,1,1,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0],
    [0,0,0,0,1,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
];
export const objects = [
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,5,0,0,0,0],
    [0,0,0,5,2,6,0,0,0,0,0,0,0,0,6,0,6,0,0,0],
    [0,0,5,0,0,0,6,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,5,0,0,0,0,0,5,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,4,0,0,0,0,6,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,4,0,4,0,0,0,3,0,4,5,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,3,0,0,0,0,4,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,3,0,0,0,0,0,6,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,5,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
];

export const initalPushyPosition = [10,9];
