export const levelName = 'Insel 7: Waimea';
export const ground = [
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,1,1,1,0],
    [0,0,0,0,0,0,1,1,1,1,0,0,1,1,1,1,1,1,1,0],
    [0,0,0,0,0,0,1,0,0,1,0,0,1,0,1,1,1,1,1,0],
    [0,0,0,0,0,0,1,0,0,1,0,0,1,0,0,1,1,1,1,0],
    [0,0,0,0,0,0,1,0,0,1,0,0,1,0,0,0,0,0,0,0],
    [0,0,1,1,1,0,1,0,0,1,0,0,1,0,0,0,0,0,0,0],
    [0,0,1,1,1,0,1,0,0,1,0,0,1,0,0,0,0,0,0,0],
    [0,0,0,1,0,0,1,0,0,1,0,0,1,0,0,0,0,0,0,0],
    [0,0,0,1,1,1,1,0,0,1,1,1,1,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
];
export const objects = [
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,7,0,7,0,0],
    [0,0,0,0,0,0,7,0,0,0,0,0,7,0,0,5,0,0,7,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,7,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,7,0,5,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,7,0,7,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,7,0,0,7,0,0,7,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
];

export const initalPushyPosition = [18,4];
